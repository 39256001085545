import React from 'react';
import Header from '../components/Header';
import Layout from '../components/Layout/Layout';
import Seo from '../components/Layout/Seo';
import Footer from '../components/Footer';
// import SeeAlso from '../sections/Industries/SeeAlso';
import IndustriesBanner from '../sections/Industries/Banner';
import LetsGetToWork from '../sections/Industries/LetsGetToWork';
import OurIndustryExpertise from '../sections/Industries/OurIndustryExpertise';

export default function IndustriesPage({location}) {
  const { state = {} } = location;
  const { subItemId = ''} = state || {};

  return (
    <Layout subItemId={subItemId}>
      <Seo title="Industries" />
      <Header location={location} />
      <IndustriesBanner />
      <OurIndustryExpertise id={'our-industry-expertise'} />
      {/*<SeeAlso id={'see-also'} />*/}
      <LetsGetToWork id={'lets-get-to-work'} />
      <Footer />
    </Layout>
  );
}
