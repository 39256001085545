import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { CustomTitle } from '../../../components/CustomText';
import { CustomIndustryCard } from '../../../components/CustomCard';
import { industriesList } from './constants';

export default function OurIndustryExpertise({id}) {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <Grid id={id} container justify={'center'} className={classes.container}>
      <Grid item xs={12} className={classes.titleContainer}>
        <CustomTitle title={t('ourIndustryExpertise')} />
      </Grid>
      <Grid item lg={6} md={8} sm={9} className={classes.textContainer}>
        <Typography variant={'body2'} align={'center'} gutterBottom>
          {t('ourIndustryExpertiseDescription')}
        </Typography>
      </Grid>
      <Grid item xs={12} container className={classes.root}>
        {industriesList(t).map((industry) => (
          <Grid key={`industry-card-${industry.id}`} item lg={6} md={6} sm={12} container className={classes.imageCard}>
            <div
              data-sal={'zoom-in'}
              data-sal-delay="100"
              data-sal-easing="ease"
            >
            <CustomIndustryCard
              title={industry.title}
              text={industry.description}
              img={industry.img}
              reverse={industry.reverse}
            />
            </div>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
