import { makeStyles } from '@material-ui/core';


export const useStyles = makeStyles(theme => ({
  container: {
    // backgroundColor: theme.palette.secondary.light,
    // borderTop: `1px solid ${theme.palette.secondary.light}`,
    // padding: '4rem 18.5rem 12rem',
    // clipPath: 'polygon(0 0, 100% 11%, 100% 100%, 0% 87%)',
    // TODO: remove when see also section will be active
    backgroundColor: 'white',
    clipPath: 'polygon(0 0, 100% 8rem, 100% 100%, 0% 100%)',
    padding: '15rem 18.5rem 10rem',
    // --- end ---
    [theme.breakpoints.down('md')]: {
      // padding: '8rem 4.5rem',
      padding: '12rem 4.5rem 9rem', // rm
    },
    [theme.breakpoints.down('sm')]: {
      // padding: '5rem 1.5rem 6rem',
      padding: '9rem 2.5rem 5rem', // rm
      clipPath: 'polygon(0 0, 100% 5rem, 100% 100%, 0% 100%)',
    },
    [theme.breakpoints.down('xs')]: {
      // padding: '5rem 1.5rem 6rem',
      padding: '9rem 1.5rem 5rem', // rm
      clipPath: 'polygon(0 0, 100% 5rem, 100% 100%, 0% 100%)', // rm
    },
  },
  textContainer: {
    paddingTop: theme.spacing(4.5)
  },
  description: {
    paddingTop: theme.spacing(2)
  },
  formContainer: {
    paddingTop: theme.spacing(8)
  },
  itemContainer: {
    paddingTop: theme.spacing(5)
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    width: '100%',
    textTransform: 'uppercase',
    padding: '.5rem 1rem',
    borderRadius: 10,
    marginTop: theme.spacing(3),
    marginLeft: 'auto',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      borderColor: 'white',
    },
  },
  industriesContainer: {
    paddingBottom: '3rem',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '1rem',
    },
  }
}));
